.ant-popover {
  min-width: 250px;
}

.ant-popover-title {
  padding: 8px 16px;
}

.popover-list-container .ant-popover-inner-content {
  padding: 0 16px;
}
