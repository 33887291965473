a {
  text-decoration: underline !important;
  color: inherit;
}

.ant-layout {
  height: 100%;
  overflow: auto;
}

.page-wrapper {
  padding: $generic-padding;
}

.bg-white {
  background-color: $white;
}

.bg-gray {
  background-color: $background-card-gray;
}

.pointer {
  cursor: pointer;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-lg {
  font-size: $font-size-lg !important;
}

.text-base {
  font-size: $font-size-base !important;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-gloria-hallelujah {
  font-family: 'Gloria Hallelujah';
}

.line-height-initial {
  line-height: initial;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-hidden {
  @extend .overflow-x-hidden, .overflow-y-hidden;
}

.default-border {
  border: 1px solid #f0f0f0 !important;
}

.warning {
  color: $warning !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.disabled {
  opacity: .3;
  cursor: not-allowed;
}


// maybe enable this when runtime errors are too annoying?
// iframe#webpack-dev-server-client-overlay {
//   display: none !important
// }