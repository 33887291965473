@media screen and (max-width: $mobile-device-width-max) {
  .ant-drawer-header {
    // use safe-area inset for iOS otherwise use 16px
    padding-top: max(env(safe-area-inset-top), 16px) !important;
  }
  .ant-drawer-wrapper-body {
    width: 100% !important;
    background-color: white;
  }
  .ant-drawer-open.no-mask {
    width: 100% !important;
  }
  .ant-drawer-body .mobile-spacing {
    margin: 0 -12px;
  }
  .ant-drawer-footer {
    margin-bottom: env(safe-area-inset-bottom);
  }
  .ant-drawer--with-mobile-padding .ant-drawer-body {
    padding: 24px;
  }
}

.ant-drawer-title span {
  color: $text-color-gray;
}

.page {
  .ant-drawer-header {
    padding-top: env(safe-area-inset-top) !important;
    padding-bottom: 0;
    @media screen and (max-width: $md) {
      z-index: $header-z-index !important;
      @include box-shadow(
        0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12)
      );
    }
    button {
      border: 0;
      margin: 0;
    }
  }
  .ant-drawer-title span {
    color: #21393f;
  }
  .ant-drawer-footer {
    padding: 0;
  }
}
