@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-list-item {
    display: block;
  }
}

.ant-list-split .ant-list-header {
  color: $headings-color;
  border-bottom: 0;
}

.ant-list-item {
  align-items: flex-start;
}

.list-header {
  margin-bottom: 0;
  padding-left: 8px;
  color: $black;
}

.v-align {
  &.ant-list-item {
    align-items: center !important;
  }

  .ant-list-item-meta-content {
    @extend .my-auto;
  }
}

.ant-list-item.ant-list-item--unstyled-title {
  .ant-list-item-meta-title {
    font-weight: normal;
    font-family: $font-family-base;
  }
}
