.gm-style-iw {
  max-width: 433px !important;
  box-shadow: 0 2px 18px 1px #00000012 !important;
  border-radius: 2px !important;
  padding: 24px !important;

  button {
    img {
      margin: 7px 3px 3px -3px !important;
      height: 20px !important;
      width: 20px !important;
    }
  }

  span {
    font-size: $font-size-base;
  }

  * {
    font-family: "Fira Sans Condensed", serif !important;
  }
}

.gm-style-iw-d {
  overflow: auto !important;
}
