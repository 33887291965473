.ant-alert {
  margin-bottom: 8px;

  &.ant-alert-default {
    border: none;
    background-color: #F1F2F5;

    .ant-alert-message {
      color: $black
    }
    .anticon{
      color: $text-color
    }
  }

  &.ant-alert-transparent {
    border: none;
    background-color: $white;

    .ant-alert-message {
      color: $black
    }
    .anticon{
      color: $text-color
    }
  }
}