.kfooter {
  padding: 16px;
  border-top: 1px solid $border-color-base;
  // width: 100%;
  background-color: $white;
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
  &.sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    z-index: $sticky-footer-z-index;
  }
}
.ant-drawer-footer .kfooter {
  padding-bottom: 16px;
}
