.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: $primary !important;
}


.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: $disabled-gray !important;
}

.am-steps-item-title {
  font-size: $font-size-base;
}

.am-steps-item-title {
  font-size: $font-size-base;
}

.ant-steps {
  h6 {
    display: inline-block;
  }

  .ant-steps-item-finish {
    cursor: pointer;

    .ant-steps-item-icon {
      border-color: $primary;
      background-color: unset;

      svg {
        fill: $primary;
      }
    }

    .ant-steps-item-tail::after {
      background-color: $primary;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      border-color: $primary;
      background-color: $primary;

      svg {
        fill: $white;
      }
    }

    .ant-steps-item-title {
      color: $primary !important;
    }
  }

  .ant-steps-item-error {
    .ant-steps-item-icon {
      background-color: $secondary;
      border-color: $secondary;

      svg {
        fill: $white;
      }
    }

    .ant-steps-item-title {
      color: $secondary !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: $white;
      border-color: $disabled-gray;

      .ant-steps-icon {
        display: none;
      }
    }

    .ant-steps-item-title {
      color: $text-color-gray !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .am-steps-item-title {
    font-size: $font-size-sm;
  }
}