.custom-card-wrapper {

  &.ant-card:not(.ant-card-bordered) {
    box-shadow: none;
  }

  & .ant-card-head {
    border: none;
  }

  .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    color: $border-color-base;
  }

  .ant-card-large .ant-card-body {
    padding-top: 8px;
  }

  .ant-card-head-title {
    padding-bottom: 8px;
    padding-top: 16px;
    white-space: normal;
  }

  .ant-list-item:first-child {
    padding-top: 16px;
  }

  .ant-card-body {
    @media screen and (max-width: 767px) {
      padding: 12px;
    }
  }
}