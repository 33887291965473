// Box Shadow
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Single side border-radius
@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Single side box-sizing
@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

// Clearfix

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}
// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin translateX($degrees) {
  -webkit-transform: translateX($degrees);
  -ms-transform: translateX($degrees); // IE9 only
  -o-transform: translateX($degrees);
  transform: translateX($degrees);
}
//Background Image
@mixin background($imgpath,$position:0 0,$repeat: no-repeat,$size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}
//Background Without Image
@mixin background-position($position:0 0,$repeat: no-repeat,$size: cover) {
  background: {
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

// Flex
@mixin display-flex($flex: flex, $flex-direction: row, $flex-wrap: wrap) {
  display: #{'-webkit'}-#{$flex};
  display: #{'-moz'}-#{$flex};
  display: #{'-ms'}-#{$flex};
  display: #{'-o'}-#{$flex};
  display: $flex;
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  -webkit-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex($flex-grow: 1, $flex-shrink: 1, $flex-basis: auto) {
  -webkit-flex: $flex-grow $flex-shrink $flex-basis;
  -ms-flex: $flex-grow $flex-shrink $flex-basis;
  flex: $flex-grow $flex-shrink $flex-basis;
}

@mixin justify-content($justify-content) {
  -webkit-justify-content: $justify-content;
  justify-content: $justify-content;
}

@mixin align-items($align-items) {
  -webkit-align-items: $align-items;
  align-items: $align-items;
}

//Border Radius

@mixin border-radius($radius: $border-radius) {
  border-radius: $radius;
}

@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}


@mixin full-width() {
  float: left;
  width: 100%;
}

@mixin full-screen-overlay($bottom: -200%, $left: 0, $height: 100%)  {
  position: fixed;
  bottom: $bottom;
  left: $left;
  height: $height;
  width: 100%;
  background-color: $white;
  z-index: 1300;
  @include transition(all 0.4s ease);
}

@mixin full-screen-overlay-top($top: 200%, $right: 0, $height: 100%, $width: 100%)  {
  position: fixed;
  top: $top;
  right: $right;
  height: $height;
  width: $width;
  background-color: $white;
  z-index: 1300;
  @include transition(all 0.4s ease);
}

@mixin flex-center() {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
