.ant-btn-primary {
  text-shadow: unset;
  box-shadow: unset;
}

.ant-btn-secondary-border {
  color: $text-color;
  border-color: $primary;
  background-color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $primary-light !important;
    background-color: $white !important;
    border-color: $primary !important;
  }
}

.ant-btn-link {
  color: $primary;

  &:hover {
    color: $primary-light;
  }
}

a {
  color: $black;

  &:hover {
    color: $primary-light;
  }
}

.ant-btn-icon-only {
  .ant-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}