.heading-color {
  color: $headings-color;
}

.text-muted {
  color: $gray-light;
}

.color-primary {
  color: $primary;
}

.text-primary-light {
  color: $primary-light;
}

.text-secondary {
  color: $secondary;
}

.text-secondary-light {
  color: $secondary-light;
}

.text-black {
  color: $black !important;
}
