.ant-input:read-only {
  border: none;
  resize: none;
}

.ant-input-lg {
  font-size: $font-size-base !important;
}

.ant-input-number {
  width: 100%;
}

.custom-card-element {
  background-color: #fff !important;
  border: 1px solid #e8e8e8;
  display: flex;
  &.StripeElement--focus, &:hover {
    border-color: #416b73;
  }
  .StripeElement {
    // over styles of legacy checkout
    background-color: #fff;
  }
}