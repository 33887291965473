.ant-modal-content {
  .ant-modal-body {
    padding-bottom: 0;
  }
}

.KModal-confirm {
  .ant-modal-content {
    .ant-modal-header {
      border: none;
      padding-bottom: 0px;
    }
  }
}

@media (max-width: $mobile-device-width-max) {

  .KModal-form,
  .KModal-default {
    top: 0;
    height: 100%;
    padding: 8px 0;
    padding-top: env(safe-area-inset-top);
    margin: 0 auto;

    .ant-modal-content {
      height: 100%;
    }

    .ant-modal-body {
      padding-bottom: 0;
      max-height: calc(100vh - 120px);
      overflow: scroll;
    }

    .ant-modal-footer {
      padding: 16px 24px 24px 24px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .KModal-confirm {
    bottom: 0;
    top: unset;
    position: fixed;
    max-width: 100% !important;
    padding-bottom: 0;
    margin-bottom: 0;

    .ant-modal-content {
      padding-bottom: calc(20px + env(safe-area-inset-bottom));
    }
  }

  .ant-modal-footer {

    .ant-space-horizontal,
    button {
      width: 100%;
    }

    .ant-space-item {
      flex: 1;
    }
  }
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: $ant-modal-z-index !important;
}