.ant-form, .ant-form-vertical {
  .ant-form-item {
    @extend .my-2;
  }
}

.ant-form-item.form-item--hide-errors {
  .ant-form-item-explain {
    display: none;
  }
}