.am-picker-popup-wrap {
  z-index: $date-picker-z-index !important;
}

.am-picker-popup-content {
  @include box-shadow($shadow);
}

.am-picker {
  padding: 0 20px;
  .am-picker-col-indicator {
    border-top: 1px solid $text-color-gray !important;
    border-bottom: 1px solid $text-color-gray !important;
  }
}

.am-picker-popup-item {
  color: $black;
  text-decoration: underline;
}

.am-picker-col-item,
.am-picker-popup-title {
  color: $headings-color;
  font-weight: bold;
  font-family: "TT Mussels";
  font-size: $font-size-base;
  text-decoration: none;
}

.mobile-date-picker .am-picker {
  flex-direction: row-reverse !important;
}

.am-picker--small {
  &.mobile-date-picker .am-picker-col {
    height: 140px !important;
  }
}
