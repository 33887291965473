.map-drawer {
  position: absolute;
  padding: 24px;
  top: 60px;
  left: 0;
  background: white;
  transition: transform .5s;
  box-shadow: $shadow;
  max-width: 350px;
}

.map-drawer-close {
  transform: translate(-100%, 0);

  .drawer-toggler .anticon {
    transform: rotate(180deg);
  }
}

.drawer-toggler {
  right: -30px;
  position: absolute;
  top: 10px;
  background: white;
  border: unset;
  opacity: 0.8;
  width: 30px;
  height: 50px;
  box-shadow: $shadow;

  .anticon {
    transition: 0.5s;
  }
}
