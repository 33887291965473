.tab-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(60px + env(safe-area-inset-bottom));
  width: 100%;
  z-index: $tab-bar-z-index !important;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 0 4px;
  padding-bottom: env(safe-area-inset-bottom);

  -webkit-box-shadow: 1px -1px 5px -2px #949495;
  -moz-box-shadow: 1px -1px 5px -2px #949495;
  box-shadow: 1px -1px 5px -2px #949495;

  @media screen and (min-width: $xl) {
    display: none;
  }

  .icon {
    span {
      font-size: 24px;
    }
  }

  div {
    flex: 1
  }
}
