.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-md {
  font-size: $font-size-base;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-xxxl {
  font-size: $font-size-xxxl;
}

@media screen and (max-width: 991px) {
  .text-lg {
    font-size: $font-size-base;
  }
}
