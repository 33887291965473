.ant-layout-header {
  background: $white !important;
  padding: 0;
  @include box-shadow(
                  0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                  0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 10px 0px rgba(0, 0, 0, 0.12)
  );
  left: 0;
  right: 0;
  z-index: $header-z-index !important;
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  height: calc(64px + env(safe-area-inset-top));
}

.menu-toggler {
  display: inline-block;
  padding: 0 16px;
  
  @media screen and (min-width: $xl) {
    display: none;
  }
}

.klarx-logo {
  display: inline-block;

  @media screen and (min-width: $xl) {
    width: 240px; // Side
    padding-left: 24px;
  }
}

.quick-actions {
  width: 32px;
  margin-right: 16px;
}

.account {
  @media screen and (max-width: $xl) {
    max-width: 300px;
  }

  @media screen and (max-width: $lg) {
    max-width: 200px;
  }

  @media screen and (max-width: $md) {
    display: none;
  }
}

.header-height {
  height: $header-height;
}