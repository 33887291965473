.ant-picker-dropdown {
  z-index: $date-picker-z-index !important;
}


@media screen and (max-width: 400px) {
	.ant-picker-datetime-panel {
		flex-direction: column;
	}

	.checkout-date-picker--time-picker {
		input {
			font-size: 10px !important;
		}
	}
}

.ant-picker-large .ant-picker-input > input {
	font-size: $font-size-base;
}
