.underlined {
  text-decoration: underline !important;
}

.hoverable:hover {
  @extend .underlined
}

.line-height-1 {
  line-height: 1;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-family-base {
  font-family: $font-family-base;
}
