.antd-iframe {
  position: relative;
  display: block;
  width: 100%;
  height: 75vh;
  padding: 0;
  overflow: hidden;
  ;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  button {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}

.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $md) {
    height: 50vh;
  }
}