/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.loading-position-middle {
  position: absolute;
  top: 35%;
  left: 50%;
  z-index: 999999;
}
