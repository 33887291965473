.ant-notification {
  margin-top: env(safe-area-inset-top);
  z-index: $notification-z-index;
}
.ant-notification-notice-wrapper {
  border-radius: 0 !important;
}

.ant-notification .success-notification {
  background-color: #EBF5E0;

  .anticon-check-circle {
    color: #21393F !important;
  }

  .ant-notification-notice-message {
    color: #292F35
  }

  .ant-notification-notice-close {
    top: 8px;
    right: 8px;
  }
}

