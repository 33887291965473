.ant-menu {
  border: none !important;
}

.ant-menu-inline-collapsed {
  .ant-menu-item-icon {
    vertical-align: middle !important;
  }
}
.ant-menu-inline-collapsed-tooltip {
  .menu-icon {
    margin-right: 8px;
  }
}

.ant-menu-item:hover {
  svg path {
    @include transition(all 0.4s);
    fill: $secondary !important;
  }
}
