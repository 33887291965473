.ant-select {
  width: 100%;
}

.ant-select-lg {
  font-size: $font-size-base;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-select { 
      display: block; // fixes broken global search for IE11
  }
}
