.ant-upload-picture-card-wrapper {
  display: flex;
  flex-direction: column-reverse;

  .ant-upload-select {
    display: flex;
    width: 100%;
    justify-content: center;

    .ant-upload {
      display: flex;

      button {
        margin: auto;
      }

    }

  }
}

.ant-upload-wrapper {
  .ant-upload-list {
    &>div,
    &>span {
      width: inherit !important;
    }
  }
}