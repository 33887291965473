/*App Layout Styles*/

#app-site,
.app-main {
  width: 100%;
  height: 100%;
}

.app-container {
  width: 100%;
}

.app-container.checkout-container{
  background-color: $white;
  height: 100%;
  .app-main-header {
    box-shadow: none;
  }
}

// .app-sidebar {
//   @include display-flex(flex, column, nowrap);
// }

.app-main-container {
  @include display-flex(flex, column, nowrap);
  width: 100%;
}

.app-header {
  min-height: $app-bar-height;
  display: flex;
  @media screen and (min-width: 1200px) {
    width: $side-nav-width;
  }
}

.app-main-content-wrapper {
  flex: 1;
  @include display-flex(flex, column, nowrap);
  width: 100%;
}

.app-container {
  &.fixed-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $side-nav-width;
      right: 0;

      @media screen and (max-width: 1199px) {
        left: 0;
        right: 0;
      }
    }
  }

  &.collapsible-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: 0;
      right: 0;
    }
  }
}
