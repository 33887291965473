.am-steps {
  .am-steps-item-tail:after {
    background-color: $disabled-gray;
  }

  .am-steps-item-finish {
    cursor: pointer;
    .am-steps-item-icon {
      border-color: $primary;
      svg {
        fill: $primary;
      }
    }
    .am-steps-item-tail::after {
      background-color: $primary;
    }
  }

  .am-steps-item-process {
    .am-steps-item-icon {
      border-color: $primary;
      background-color: $primary;
      svg {
        fill: $white;
      }
      .am-steps-icon {
        visibility: hidden;
      }
    }
  }

  .am-steps-item-wait {
    .am-steps-item-icon {
      background-color: $disabled-gray;
      .am-icon { visibility: hidden; }
    }
    .am-steps-item-title {
      color: $disabled-gray;
    }
  }

  .am-steps-item-title {
    font-weight: normal;
  }

  &.small-steps {
    &.am-steps-horizontal {
      .am-steps-item-content {
        display: none;
      }
      .am-steps-item-tail {
        margin-left: 22px;
      }
      .am-steps-item-icon {
        margin-left: 12px;
      }
    }
  }
}

.checkout-steps {
  .am-steps-item:nth-child(2), .am-steps-item:nth-child(3) {
    .am-steps-item-content {
      margin-left: -10px;
      width: 120px;
    }
  }
}