.single-collapse {
  .ant-collapse-header {
    padding: 0 !important;
    color: $text-color !important;
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
