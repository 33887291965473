.ant-table {
  .ant-table-thead {
    tr {
      th {
        background-color: #E8E8E8;
        border-bottom: 1px solid #D8D8D8;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .ant-table {
    display: block;

    .ant-table-container::after, .ant-table-cell-fix-left-first::after, .ant-table-cell-fix-left-last::after {
      display: none;
    }

    table, tbody {
      display: flex !important;
      flex-direction: column;
      width: 100% !important;
    }

    &-thead {
      display: none;
    }

    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }

        &.td-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.checkout-table .ant-table {
  .ant-table-thead {
    tr {
      th {
        background-color: white !important;
        border-bottom: unset !important;
      }
    }
  }

  .ant-table-tbody {
    tr > td {
      border-bottom: unset !important;
    }
  }
}

.checkout {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-header {
    padding: 0 !important;
    padding-inline-start: 0 !important;
  }
}