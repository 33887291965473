.ant-layout-sider {
  background: $white !important;

  .ant-menu-item,
  .ant-menu-submenu-title {
    svg {
      vertical-align: inherit;
    }
  }
}

.ant-menu {
  .ant-menu-item-icon svg {
    font-size: 24px;
  }
}

.ant-layout-sider {
  .ant-btn {
    .anticon {
      line-height: 1;
    }
  }
}

.ant-layout-sider-children {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-menu-item.menu-item--with-button {
  padding: 0;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-sider-collapsed .ant-menu-item.menu-item--with-button {
  button {
    span:first-child {
      line-height: unset;
    }
    span:last-child {
      display: none;
    }
    svg {
      font-size: 14px;
    }
  }
}

.ant-layout-sider-collapsed {
  .support-description {
    display: none;
  }
  .support-contacts {
    flex-direction: column;
  }
}
