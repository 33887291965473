@use 'sass:math';

.am-calendar {
  .date-picker {
    padding-bottom: 0;
  }
  .content {
    z-index: $calendar-z-index !important;
  }
  .header {
    margin-top: calc(env(safe-area-inset-top) + 5px); // 5px is default margin
    position: relative;
  }
  .confirm-panel {
    padding-bottom: calc(env(safe-area-inset-bottom) + 8px); // 8px is default padding

    .button {
      width: unset;
      padding: 8px;
    }
  }
  .single-month .row .cell,
  .week-panel .cell {
    width: math.div(100%, 7);
  }
}
